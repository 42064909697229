<template>
  <div class="setting">
    <div class="param-title">参数设置</div>
    <div class="wx3">
      <el-form
        ref="form"
        label-position="top"
        :model="form"
        label-width="200px"
        size="small"
      >
        <el-form-item label="企业微信corpId：">
          <el-input v-model="form.corpid" style="width:100%"></el-input>
        </el-form-item>
        <el-form-item label="企业微信应用Secret:">
          <el-input v-model="form.corpsecret"></el-input>
        </el-form-item>
        <el-form-item label="agentid:">
          <el-input v-model="form.agentid"></el-input>
        </el-form-item>
      </el-form>
      <div class="btns" v-if="idAdmin">
        <el-button size="medium" type="primary" :loading="loading" @click="submitForm()">确定</el-button>
        <el-button size="medium" @click="resetForm()">清空</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {getSecret,editSecret} from "@/api/iswx3";
export default {
  data() {
    return {
      form: {
        corpid: "",
        corpsecret: "",
        agentid:"",
      },
      loading:false,
      idAdmin:true,
    };
  },
  created(){
      this.getCorpId();
  },
  methods: {
    getCorpId() {
      this.idAdmin=JSON.parse(sessionStorage.getItem('userInfo')).role==1
      getSecret().then(res => {
        this.form=res.data
      })
      .catch((err) => {
        console.log(err);
      });
    },
    //   提交
    submitForm() {
      let form = this.form;
      if (!form.corpid.trim() && !form.corpsecret.trim() && !form.agentid.trim()) {
          this.$message({
            type:'warning',
            message:"参数不能为空"
          })
          return
      }
      this.loading=true;
      let data=[{key_name:"corpid",value_name:form.corpid},{key_name:'corpsecret',value_name:form.corpsecret},
        {key_name:'agentid',value_name:form.agentid}]
      editSecret(data).then(res => {
        this.$message({
          type: "success",
          message: '设置成功！',
        });
        this.loading=false;
      })
      .catch((err) => {
        console.log(err);
        this.loading=false;
      });
    },
    //   重置
    resetForm() {
      this.form = {};
    },
  },
};
</script>

<style lang="less" scoped>
.setting {
  //padding: 25px;
  .param-title {
    height: 48px;
    line-height: 48px;
    border-bottom: 1px solid #DBDBDB;
    padding-left: 24px;
  }
  .wx3 {
    padding: 64px;
    /deep/.el-form {
      width: 370px;
      .el-form-item__label {
        font-size: 16px;
        font-weight: 500;
        color: #000;
        padding-bottom:5px;
      }
    }
    .btns {
      padding-top: 30px;
    }
  }
}
.tips {
  width: 100%;
  height: 60px;
  box-sizing: border-box;
  padding: 10px 30px;
  background: rgba(245, 247, 250, 1);
  border-left: 3px solid #0082ef;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 37px;
  .tips-title {
    color: #808080;
    font-size: 18.5px;
    margin-right: 14px;
  }
  .tips-msg {
    font-size: 12.5px;
    line-height: 22px;
    color: #808080;
    margin: 0;
  }
}
</style>